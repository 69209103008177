.categories{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    background-color: #fff;
    margin-inline: auto;
    margin-bottom:  20px;
    overflow: hidden;
}

.category{
    margin: 3px;
    /*outline: 1px solid;*/
}

.category_name{
    font-weight: 400;
    font-size: 13px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark-grey);
    opacity: 0;
    transition: 0.3s;
}
.category_img{
    background-color: #fff;
    position: relative;
    display: flex;
    justify-content: center;

}

.category_img img{
    transition: 0.3s;
    height: 175px;
    /*border-bottom: 1px solid transparent;*/
    object-fit: cover;
    object-position: center;
    /*outline: 3px solid red;*/
}

.category_img a:hover{
    outline: 2px solid var(--red) !important;
}

.main-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.outlet{
    flex: 1;
    /*padding: 0 10px;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.main{
    width: 100%;
}

@media screen and (max-width: 1100px) {
    .category_img .lazy-load-image-background.blur.lazy-load-image-loaded > img{
        height: 100px;
    }
}

@media screen and (max-width: 860px) {
    .categories{
        display: none;
    }
}

.slider_img{
    width: 100%;
    margin-top: 165px;
}

