.container{
    top: 0;
    position: fixed;
    z-index: 10;
    height: 150px;
    width: 50%;
    min-width: 450px;
    background-color: #fff;
    transform: translateX(-100vw);
    transition: all 0.5s linear;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
}
li{
    padding: 0;
    background-color: #fff;
    margin-bottom: 10px;
}
li a{
    color: var(--red);
    font-size: 20px;
    cursor: pointer;
}
li a:hover{
    opacity: 0.8;
}
.language_container span{
    color: var(--red);
    font-size: 18px;
}
.language{
    padding: 2px 5px;
    border-radius: 4px;
}
.close{
    font-size: 30px;
    color: var(--red);
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
.close:hover{
    opacity: 0.7;
}

@media screen and (max-width: 800px) {
    .container{
        display: block;
    }
}

@media screen and (max-width: 450px) {
    .container{
        min-width: 250px;
    }
}

