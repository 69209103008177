.modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal_container{
    /*width: 1000px;*/
    width: max-content;
    border-radius: 5px;
    padding: 20px;
    background-color: #fff;
    position: relative;
}

.modal_close_btn{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}