.options {
    display: flex;
    gap: 5px;
    border-bottom: 5px solid var(--red);
}

.options > div{
    flex: 1;
    background-color: white;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border: 1px solid var(--red);
    border-bottom: none;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 40px;
    line-height: 40px;
    color: var(--dark-grey);
    /*word-break: break-all;*/
    overflow: hidden;
}

.options > div.vin{
    cursor: not-allowed;
    opacity: 0.5;
}

.options > div:not(.vin):hover{
    cursor: pointer;
    background-color: var(--red);
    transition: 0.2s all;
    color: white;
}

.search_input_container{
    padding: 5px;
    padding-top: 0;
    background-color: var(--red);
    background-image: var(--red-bg);
    display: grid;
    grid-template-columns: 6fr 1fr 1fr;
    gap: 10px;
/*    position: absolute;*/
/*    top: 45px;*/
/*    right: 0;*/
/*    left: 0*/
}

@keyframes loadingAnimation {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.loading_btn{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px dashed red;
    animation: loadingAnimation 1s linear infinite;
}

.search input, .search textarea{
    outline: 0;
    border: 0;
    width: 100%;
    padding: 8px;
    font-size: 20px;
}

.search_input_container button{
    border: 3px solid var(--red);
    background-color: #fff;
    outline: 0;
    font-weight: bold;
    color: var(--red);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_input_container button:hover{
    color: var(--red);
    background-color: white;
}

.active-option{
    background-color: var(--red) !important;
    color: white !important;
}

.on_order_form  input, .on_order_form textarea{
    width: 100%;
    padding: 8px;
    display: block;
    border: 1px solid grey;
}

.on_order_form{
    margin-top: 10px;
}

.submit_btn{
    background-color: var(--red);
    color: white;
    border-radius: 5px;
    border: 3px solid transparent;
    padding: 5px 15px;
    cursor: pointer;
    font-weight: bold;
    transition: 0.3s;
}

.submit_btn:hover{
    color: var(--red);
    background-color: #fff;
    border: 3px solid var(--red);
}

.on_order{
    margin-top: 1px;
}

.search_ref_detail{
    outline: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 190px;
}

.num_font_size{
    font-size: 25px;
}

@media screen and (max-width: 420px){

    .options{
        flex-wrap: wrap;
        gap: 0;
    }
    .options > div{
        gap: 0;
        min-width: 210px;
    }
}

@media screen and (max-width: 600px){
    .search_input_container{
        grid-template-columns: 1fr;
    }
    .search_input_container button{
        display: none;
    }
    .search input, .search textarea{
        font-size: 15px;
    }
}