.pagination{
    font-size: 18px;
    display: flex;
    color: black;
    margin: 20px 0;
}

.pagination li{
    outline: 1px solid;
    padding: 10px 15px;
    background-color: dodgerblue;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.pagination .active_page, .pagination li:hover{
    background-color: royalblue;
}
.pagination  .inactive{
    opacity: 0.7;
    cursor: not-allowed;
}

@media screen and (max-width: 400px) {
    .pagination li{
        padding: 1px 10px;
    }
}