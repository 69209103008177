.container{
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.title{
    text-align: center;
    margin: 20px 0;
}

@media screen and (max-width: 940px) {
    .container{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 624px) {
    .container{
        grid-template-columns: repeat(1, 1fr);
    }
}