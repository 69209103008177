.empty_cart_container{
    padding: 20px 0;
}
.empty_cart{
    margin-top: var(--mt-from-search);
    text-align: center;
}
.empty_cart_img{
    width: 60%;
    max-width: 550px;
    margin: 20px auto;
    background-color: #fff;
}
.empty_cart_img img{
    width: 100%;
    object-fit: cover;
}
.cart_table_container{
    display: flex;
    margin-top: var(--mt-from-head);
    padding: 20px;
}

.cart_table{
    width: 100%;
    font-size: 20px;
}
/*.cart_table tr, .cart_table th {*/
.cart_table tr, .cart_table th, .cart_table td{
    border-collapse: collapse;
    border: 1px solid grey;
    padding: 10px;
}
.cart_table td{
    margin: 0 5px;
}
.cart_item_img img{
    max-width: 70px;
}

.remove_product{
    color: var(--red);
    vertical-align: center;
    text-align: center;
    font-size: 30px;
    cursor: pointer;
}

.sub_total_container{
    width: 250px;
    background-color: var(--bg-silver);
    margin: 10px 0 10px auto;
    padding: 15px;
    font-size: 20px;
}
.price_container{
    /*font-size: 20px;*/
    color: var(--dark-grey);
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.price_container p{
    display: flex;
    align-items: end;
}
.sub_total_title{
    font-weight: bold;
}
.amd{
    line-height: 25px;
}
.order_btn{
    background-color: var(--red);
    color: white;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
    border: 2px solid var(--red);
    cursor: pointer;
}
.order_btn:hover{
    background-color: #fff;
    color: var(--red)
}
.field_error{
    height: 20px;
    font-size: 15px;
    color: var(--red)
}
.phone input{
    width: 100%;
    padding: 5px;
    font-size: 18px;
}
.cart{
    flex: 1;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    padding: 10px;
}
.phone_num{
    font-size: 16px;
    margin: 10px 0;
}

.disabled{
    opacity: 0.7;
    cursor: not-allowed;
}

@media screen and (max-width: 1140px) {
    .cart_table_container{
        padding: 2px;
    }
    .cart_table_head th{
        font-size: 16px;
    }
}


@media screen and (max-width: 940px) {
    .cart_table{
        overflow: auto;
    }
    .cart_table_container{
        padding: 0 20px;
    }
    .cart_table_head{
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .cart_table th, .cart_table td{
        font-size: 14px;
    }
    .cart_table_container{
        padding: 2px;
    }
    .cart{
        padding: 0;
    }
    .cart_table tr, .cart_table th{
        padding: 0;
    }
}