.card{
    position: relative;
    padding: 10px;
    /*box-shadow: var(--box-shadow);*/
    /*border-radius: 10px;*/
    font-size: 16px;
    font-weight: 300;
    background-color: #fff;
    /*width: 400px;*/
    transition: all 300ms ease-in-out;
}

.card:hover{
    padding: 10px;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
}
.product_img{
    display: flex;
    justify-content: center;
    position: relative;
}
.product_img a{
    width: 100%;
}
.product_img img{
    min-width: 200px;
    max-width: calc(100% - 20px);
    height: 200px;
    object-fit: contain;
    display: block;
}
.card > p{
    white-space: nowrap;
}
.card > p span{
    font-weight: bold;
}
.add_to_cart{
    color: white;
    background-color: var(--red);
    padding: 10px;
    outline: 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 160px;

}
.card_actions{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 10px;
}

.product_number_input{
    width: 40px;
    border-radius: 5px;
    border: 1px solid gray;
    padding-left: 5px;
    font-size: 16px;
}

.compare_btn{
    color: var(--red);
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    border: 0;
    outline: 0;
}

.compare_box{
    color: grey;
    border-top: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    padding-top: 5px;
}

.price_types_container{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    margin-bottom: 5px;
    height: 50px;
}
.price_type{
    display: flex;
    align-items: center;
    gap: 5px;
}
.product_brand{
    text-transform: uppercase;
}

.product_brand span{
    font-weight: 900;
}

.product_discount{
    background-color: #FF4444;
    color: white;
    padding: 3px 7px;
    font-size: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.product_price{
    display: flex;
    justify-content: space-between;
}
.product_price_text span{
    font-weight: bold;
}
.product_price_dram{
    margin-bottom: -2px;
}
.product_price_marked{
    color: var(--price-color)
}

.product_count_container{
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.product_count_container button{
    border: 0;
    outline: 0;
    background-color: #7790a2;
    padding: 4px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
}

.product_count_container button:hover{
    opacity: 0.8;
}

.product_count_container input{
    max-width: 40px;
    padding: 3px;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.product_count_container input::-webkit-outer-spin-button,
.product_count_container  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.product_count_container input[type=number] {
    -moz-appearance: textfield;
}

.card_disabled_item{
    opacity: 0.7 !important;
    cursor: not-allowed !important;
}

.sold_out{
    opacity: .3;
    position: absolute;
    top: 60px;
    width: 80%;
}

@media screen and (max-width: 1280px) {
    .card{
        /*min-width: 450px;*/
    }
}

@media screen and (max-width: 1150px) {
    .card{
        /*min-width: 350px;*/
    }
}

@media screen and (max-width: 1100px) {
    .inTopSelling{
        /*margin: 0 auto;*/
        /*max-width: 500px;*/
    }
}

@media screen and (max-width: 960px) {
    .card{
        /*margin: 0 auto;*/
        /*flex: 1;*/
        /*max-width: 90%;*/
    }
}

@media screen and (max-width: 580px) {
    .card{
        /*width: 100%*/
    }
}


@media screen and (max-width: 500px) {
    .card > p{
        white-space: initial;
    }
}
