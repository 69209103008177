.compare_product{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    max-width: 300px;
    padding: 10px;
}
.compare_product_img{
    width: 100%;
    max-height: 200px;
}

.compare_product_img img{
    width: 100%;
    height: 100%;
    display: block;
}