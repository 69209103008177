.top_banner_wrapper{
    background-color: var(--red);
    background-image: var(--red-bg);
}
.top_banner{
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    color: #fff
}
.icon{
    font-size: 25px;
}
.icon_container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.icon_container a{
    color: #fff;
}

.delivery{
    display: flex;
    align-items: center;
    gap: 30px;
}

.delivery_title{
    display: flex;
    flex-direction: column;
}

.language{
    border: 0;
    outline: 0;
    padding: 2px 5px;
    border-radius: 4px;
}

.language img{

}

@media screen and (max-width: 940px) {
    .top_banner {
        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {
    .top_banner_wrapper{
        display: none;
    }
}