.footer_container{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    color: #fff;
    font-size: 16px;
    flex-wrap: wrap;
    gap: 10px;
}
.footer_container a{
    color: white;
}
.footer_container a:hover{
    opacity: 0.8;
}
.footer_container > div{
    flex: 1;
}

.img_container{
    width: 150px;
    margin-bottom: 10px;
    padding: 5px;
    background-color: #fff;
}

.icon_container, .icon_container a{
    display: flex;
    align-items: center;
}

.icon{
    margin-right: 10px;
}

.map{
    min-width: 300px;
    max-width: 600px;
}

.map iframe{
    width: 100%;
    height: 100%;
    outline: 0;
    border: 0;
}

.links{
    white-space: nowrap;
    margin-top: 30px;
}

.footer_text{
    margin-left: 20px;
}
.footer_info{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;
    max-width: 600px;
}
.links p{
    margin-bottom: 10px;
}
.links p a{
    border-bottom: 1px solid;
}

.contact_info{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.phone_nums{
    white-space: nowrap;
}
.phone_right_space{
    margin-right: 15px;
}

@media screen and (max-width: 1118px) {
    .contact_info{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
    .map{
        height: 300px;
    }
}

.connected_title{
    font-weight: bold;
}

@media screen and (max-width: 759px) {
    .map{
        max-width: initial;
        width: 100%;
    }
}

@media screen and (max-width: 565px) {
    .contact_info{
        flex-direction: column;
    }
    .facebook{
        margin-left: -30px;
    }
    .footer_info{
        flex-wrap: wrap;
    }
    /*.logo_title{*/
    /*    white-space: initial;*/
    /*}*/
}

@media screen and (max-width: 420px) {
    .map, .links{
        min-width: 250px;
    }
}