.header{
    position: sticky;
    width: 100%;
    z-index: 2;
    top: 0;
    background-color: #fff;
}

.header_content{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 10px;
    background-color: #fff;
    /*padding: 5px 0 15px;*/
    padding-top: 5px;
    padding-bottom: 15px;
}

.logo-container{
    align-self: start;
}

.logo-container-left{
    max-width: 400px;
}

.header_cart{
    background-color: var(--red);
    color: white;
    height: 60px;
    align-self: start;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 20px;
}
.header_cart_link{
    color: white;
    position: relative;
    margin-top: 10px;
    /*display: inline-block;*/
}

.header_cart_link:after{
    content: attr(data-count);
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: 0;
    width: 20px;
    height: 20px;
    border: 2px solid var(--dark-grey);
    border-radius: 50%;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
}

.header_cart:hover{
    border: 3px solid var(--red);
    background-color: #fff;
}

.header_cart:hover .header_cart_link{
    color: var(--red)
}
.logo-text{
    font-size: 16px;
}

.logo_cart{
    display: none;
    font-size: 30px;
    color: var(--red);
    position: relative;
    margin-right: 10px;
}

.logo_cart:after{
    content: attr(data-count);
    position: absolute;
    top: -13px;
    right: -7px;
    bottom: 0;
    width: 20px;
    height: 20px;
    border: 2px solid var(--dark-grey);
    border-radius: 50%;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
}

.header_trial{
    outline: 2px solid orange;
    display: flex;
    align-items: stretch;
    padding: 10px;
}

.header_trial2{
    flex: 1;
    outline: 2px solid silver;
}

.slider_arrow{
    position: absolute;
    left: 0;
    top: 20px;
    color: var(--red);
    font-size: 40px;
    cursor: pointer;
    display: none;
}

.slider_arrow:hover{
    opacity: 0.7;
}

@media screen and (max-width: 1000px){
    .header_content{
        grid-template-columns: 1fr;
        /*padding: 5px 5px 15px;*/
    }
    .header_cart{
        display: none;
    }
    .logo-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo_cart{
        display: block;
    }
}

@media screen and (max-width: 800px){
    .slider_arrow{
        display: block;
    }
    .logo-container-left{
        margin-left: 50px;
    }
}

@media screen and (max-width: 420px){
    .logo-container{
        padding: 10px 0;
        gap: 20px;
    }
    .logo-container > a{
        margin-bottom: -10px;
    }
    .logo-text {
        display: none;
    }
}