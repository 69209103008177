:root{
  --color-active: deepskyblue;
  --feature-blue: #0059ff;
  --color-inactive: grey;
  --orange: #FFAF1E;
  --dark-orange: #a26d0c;
  --silver: #F1F1F1;
  --bg-silver: #EFEFEF;
  --product_property: #617e93;
  --dark-grey: #312c2c;
  --red: #d32420;
  --success-color: #28a745;
  --box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  --red-bg: linear-gradient(#d32420, #751927);
  --mt-from-search: 35px;
  --inner-container-width: 1200px;
  --price-color: #f53d0a;
  --mt-from-head: 140px;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*overflow: hidden;*/
}
body{
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

ul, li{
  list-style-type: none;
}

.app{
  background-color: var(--bg-silver);
  min-height: 100vh;
  border: 1px solid var(--bg-silver);
}

a{
  text-decoration: none;
}
.active-link{
  color: dodgerblue;
}

.app_container{
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
  overflow: hidden;
  /*background-color: dodgerblue;*/
}

.red_container{
  background-image: var(--red-bg);
}

.validation_error{
  color: var(--red);
  height: 20px;
}

.disabled{
  cursor: not-allowed !important;
  opacity: 0.7
}

.white{
  background-color: #fff;
}

.left_bar_show{
  left: 0 !important;
}

.filter_btn_hide{
  /*left: -1000px !important;*/
  opacity: 0 !important;
}


@media screen and (max-width: 900px) {
  .app_container{
    padding: 0 3px;
  }
}

.container {
  padding: 40px;
  background: #419be0;
}

.slick-slide img {
  margin: auto;
}