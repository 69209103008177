.compare_container{
    display: flex;
    margin: 100px auto 10px;
    gap: 10px;
    max-width: 1000px;
}
.compare_item_descriptions{
    margin-top: 10px;
}
.compare_item{
    background-color: #fff;
    padding: 10px;
    flex: 1;
    max-width: 250px;
    min-width: 250px;
    position: relative;
}
.remove_compare{
    font-size: 20px;
    position: absolute;
    top: 0;
    right: 0;
}
.remove_compare:hover{
    opacity: 0.6;
    cursor: pointer;
}
.compare_item_title{
    font-size: 13px;
    color: var(--red);
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
}
.compare_item img{
    display: block;
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
}


@media screen and (max-width: 1100px) {
    .compare_container{
        max-width: initial;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}