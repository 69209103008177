.top_selling_container{
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.top_selling_title{
    text-align: center;
    font-size: 40px;
}


@media screen and (max-width: 1100px) {
    .top_selling_container{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 760px) {
    .top_selling_container{
        grid-template-columns: repeat(1, 1fr);
    }
}