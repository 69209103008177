.slider_container {
    margin-bottom: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
img {
    width: 100%;
    display: block;
    max-height: 550px;
    object-fit: cover;
}