.notification{
    border-radius: 5px;
    padding: 20px;
    position: relative;
    z-index: 20;
    background: #FFAF1E;
}

.close_notification_btn_container{
    position: absolute;
    top: 10px;
    right: 10px;
}

.close_notification_btn{
    cursor: pointer;
    font-size: 20px;
}

.close_notification_btn:hover{
    opacity: 0.5;
}

.message{
    color: white;
    text-align: center;
    font-size: 18px;
}