.products_wrapper{
    /*max-width: var(--inner-container-width);*/
    /*margin: var(--mt-from-head) auto 0;*/
    margin-top: 80px;
    /*overflow: hidden;*/
}
.product_wrapper > div{
    border: 2px solid #fff;
    margin-bottom: 20px;
    margin-top: 10px;
}

.product_container{
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    font-size: 16px;
}
.product_container > div{
    flex: 1;
}

.imgs{
    display: flex;
    flex-wrap: nowrap;
}
.main_img_container{
    width: 300px;
    cursor: pointer;
    box-shadow: 1px 1px 12px #ccc;
}
.main_img_container img{
    display: block;
    width: 100%;
    height: 300px;
    object-fit: contain;
}
.side_imgs{
    /*border: 1px solid;*/
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
}
.side_imgs > img{
    width: 50px;
    height: 50px;
    object-fit: contain;
    padding: 5px;
    border: 2px solid transparent;
}
.side_imgs > img:hover, .focused{
    border: 2px solid var(--red) !important;
}

.product_feature{
    color: var(--dark-grey);
    display: flex;
    align-items: center;
}

.product_feature span{
    color: var(--product_property);
}

.product_feature span{
    flex: 1;
}
.about{
    color: var(--dark-grey);
    font-weight: bold;
}
.feature_title{
    min-width: 130px;
    text-transform: uppercase;
}
.feature_dots{
    min-width: 100px;
    border-top: 1px dotted var(--dark-grey);
    margin: 0 10px;
}
.feature_value{
    color: var(--feature-blue);
    min-width: 130px;
}
.currency{
    margin-bottom: -2px;
}
.about_container{
    padding-left: 10px;
}
.cart_container{
    margin-left: 50px;
    max-width: 250px;
}
.price_original_container, .price_copy_container{
    color: var(--price-color)
}
.price_copy_container{

}
.price_original, .price_copy{
    font-weight: bold;
    font-size: 21px;
    display: flex;
    align-items: center;
}

.cart_btn_container{
    display: flex;
    justify-content: space-between;
}
.cart_btn{
    background-color: var(--red);
    color: white;
    font-size: 14px;
    outline: 0;
    border: 3px solid transparent;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}
.cart_btn:hover{
    color: var(--red);
    background-color: #fff;
    border: 3px solid var(--red)
}
.discount{
    font-size: 9px;
    padding: 2px 5px;
    background-color: var(--price-color);
    color: #fff;
    border-radius: 3px;
}
.discounted_price{
    display: inline-flex;
    align-items: center;
    color: var(--dark-grey);
    text-decoration: line-through;
    margin-left: 10px;
}
.price_name{
    font-size: 15px;
    font-weight: bold;
    text-decoration: none !important;
    color: var(--red);
    margin-right: 5px;
    display: flex;
    gap: 5px;
}
.product_count{
    min-width: 30px;
    width: 95%;
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.product_count button{
    background-color: #fff;
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: bold;
    padding: 2px 4px;
    outline: 0;
    border: 0;
    cursor: pointer;
}
.product_count input {
    width: 50%;
    outline: 0;
    text-align: center;
    padding: 3px;
}
/* Chrome, Safari, Edge, Opera */
.product_count input::-webkit-outer-spin-button,
.product_count  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.product_count input[type=number] {
    -moz-appearance: textfield;
}

.price{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ref_container{
    background-color: #fff;
    font-size: 16px;
    margin-top: 10px;
    padding: 5px;
}
.ref_num{
    font-size: 20px;
    color: #4A4A4A;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}
.refs{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}
.ref_item{

}
.ref_key{
    font-weight: bold;
}
.ref_value{
    margin-right: 10px;
}
.category_container{
    display: flex;
    position: relative;
    z-index: 0;
}
.category_container_products{
    margin-left: 205px;
}

.filter_btn{
    display: none;
    padding: 10px;
    text-align: center;
    outline: 0;
    border: 0;
    font-size: 17px;
    background-color: #fff;
    color: var(--red);
    margin: 10px auto;
    transition: all 0.3s;
    border-radius: 5px;
    cursor: pointer
}
.compare_btn:hover{
    background-color: var(--red);
    color: #fff;
}

.no_product{
    height: 200px;
    margin: 10px auto;
}
.no_product img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.action_btn{
    background-color: #7790a2 !important;
    padding: 5px !important;
    border-radius: 3px !important;
    color: #fff !important;
    margin: 0 1px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.uc{
    text-transform: uppercase;
}

.modal_img_container{
    display: flex;
    justify-content: center;
    margin: 15px;
    box-shadow: 2px 2px 12px #ccc, 2px 2px 12px #ccc;
}
.modal_img_container img{
    max-width: 620px;
    object-fit: contain;
}

.loading{
    height: 150px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading img{
    height: calc(100% - 60px);
    object-fit: contain;
}

@media screen and (max-width: 1050px) {
    .cart_container{
        margin-left: 10px;
    }
}

@media screen and (max-width: 1000px) {
    .product_container{
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 790px) {
    .products_in_category{
        flex: 1;
    }
    .feature_dots{
        min-width: 50px;
    }
}


@media screen and (max-width: 704px) {
    .product_container > div{
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 587px) {
    .product_container{
        justify-content: flex-start;
    }
    .cart_container{
        margin: 10px;
        max-width: initial;
    }
    .product_count input{
        max-width: 100px;
    }
    .about_container{
        margin-right: 50px;
    }
}

@media screen and (max-width: 1118px) {
    .no_product{
        margin: 10px auto 57px;
    }
}

@media screen and (max-width: 1663px) {
    .no_product{
        margin: 10px auto 142px;
    }
}

@media screen and (min-width: 1664px) {
    .no_product{
        margin: 10px auto 240px;
    }
}

@media screen and (max-width: 537px) {
    .about_container{
        margin-right: 0;
    }
}

@media screen and (max-width: 537px) {
    .product_container{
        padding: 0;
    }
}

@media screen and (max-width: 470px) {
    .feature_title{
        font-size: 14px;
    }
    .feature_value{
        font-size: 12px;
    }
}
@media screen and (max-width: 365px) {
    .product_container{
        padding: 0;
    }
    .main_img_container img{
        width: calc(100% - 50px)
    }
}

@media screen and (max-width: 580px) {
    .products_in_category{
        margin-left: 0;
        width: 100%;
        display: flex;
    }
    .filter_btn{
        display: block;
    }
}