.products{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 5px auto 10px;
}

.products .card{
    flex: 1;
}

.products_wrapper{
    min-height: 340px;
}

.compare_all_btn{
    position: fixed;
    top: 50%;
    right: 0;
    color: var(--red);
    font-size: 30px;
    cursor: pointer;
}

.compare-products{
    display: flex;
    gap: 20px;
}

.no_product{
    min-width: 400px;
    position: absolute;
    left: calc(50% - 200px);
    top: 20px;
}

.no_product img{
    object-fit: cover;
    object-position: center;

}

@media screen and (max-width: 1280px) {
    /*.products{*/
    /*    display: flex;*/
    /*    flex-wrap: wrap;*/
    /*    justify-content: center;*/
    /*    width: calc(100vw - 230px);*/
    /*}*/
}

@media screen and (max-width: 940px) {
    .products{
        grid-template-columns: repeat(2, 1fr);
        /*display: flex;*/
        /*justify-content: center;*/
    }
}

@media screen and (max-width: 1050px) {
    /*.products.in_category{*/
    /*    grid-template-columns: repeat(2, 1fr);*/
    /*}*/
}

@media screen and (max-width: 890px) {
    /*.products{*/
    /*    grid-template-columns: repeat(2, 1fr);*/
    /*}*/
}
@media screen and (max-width: 790px) {
    /*.products.in_category{*/
    /*    grid-template-columns: repeat(1, 1fr);*/
    /*}*/

}

@media screen and (max-width: 600px) {
    .products{
        grid-template-columns: repeat(1, 1fr);
        width: 100%
    }
    .products .card{
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }
    .products_wrapper{
        width: 100%;
    }
}

@media screen and (max-width: 300px) {
    .no_product{
        max-width: 250px;
        left: calc(50% - 125px)
    }
}